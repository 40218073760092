import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/shahid/Desktop/Projects/Personal/madrasatulilm.com/src/views/layouts/MainLayout.js";
import { Helmet } from 'react-helmet';
import { Box, Breadcrumb, Divider, SEO, Text, BookList } from '../../../views/components';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO pageTitle="ইংরেজী" mdxType="SEO" />
    <Box maxWidth={960} margin="0 auto" padding={{
      xs: 3,
      sm: 4
    }} mdxType="Box">
      <Text variant="h2" textAlign="center" mdxType="Text">
  ইংরেজী
      </Text>
      <Divider mdxType="Divider" />
      <Breadcrumb links={[{
        url: '/',
        name: 'নীড়পাতা'
      }, {
        url: '/study-materials/',
        name: 'শিক্ষাপোকরণ'
      }, {
        url: '/study-materials/english',
        name: 'ইংরেজী',
        isCurrentPage: true
      }]} mdxType="Breadcrumb" />
      <Divider mdxType="Divider" />
      <BookList books={[{
        title: 'Learn English Alphabets with Words',
        link: '/study-materials/english/learn-alphabets-with-words'
      }, {
        title: 'ছবি দেখে ইংরেজী শব্দ শেখা',
        link: '/study-materials/english/learn-english-by-watching-pictures'
      }]} mdxType="BookList" />
    </Box>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      